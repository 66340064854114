@import "src/styles/variables";

.chart-tooltip {
  padding: 16px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 8px 0px #888888;
  
  & > p:last-child {
    margin-bottom: 0;
  }
  
  .tooltip-text {
    color: $curiousBlue;
  }
}