@import "src/styles/variables";

#root {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
}

#header {
  height: $header-height;
  background-color: $header-bg;
  color: $header-color;

  @include media-breakpoint-down(md) {
    height: $header-height-sm;
  }

  .header-toolbar {
    height: 100%;
    padding-left: 0;
    padding-right: 0;

    .header-section {
      height: 100%;
      display: flex;
      flex-direction: row;

      .header-button {
        height: 100%;
        font-size: 1.25rem;
        border-radius: 0;
        padding-left: 48px;
        padding-right: 48px;

        @include media-breakpoint-down(lg) {
          padding-left: 36px;
          padding-right: 36px;
        }

        @include media-breakpoint-down(md) {
          padding-left: 24px;
          padding-right: 24px;
        }

        @include media-breakpoint-down(sm) {
          padding-left: 18px;
          padding-right: 18px;
        }
      }

      .hamburger-button {
        border-left: none;
        padding-left: 32px;
        padding-right: 32px;

        @include media-breakpoint-down(md) {
          padding-left: 20px;
          padding-right: 24px;
        }

        @include media-breakpoint-down(md) {
          padding-left: 18px;
          padding-right: 18px;
        }
      }

      .location-selector {
        padding-left: 24px;

        .business-icon {
          font-size: 2.5rem;

          @include media-breakpoint-down(md) {
            display: none;
          }
        }

        .text-container {
          margin-left: 24px;

          @include media-breakpoint-down(sm) {
            margin-left: 0;
          }

          .location-name {
            color: inherit;
            font-size: 1.5rem;
            font-weight: 600;
            text-transform: uppercase;
            white-space: nowrap;

            @include media-breakpoint-down(sm) {
              font-size: 1.25rem;
            }
          }

          .location-secondary {
            color: inherit;
            font-size: 1.25rem;
            white-space: nowrap;

            @include media-breakpoint-down(sm) {
              font-size: 1rem;
            }
          }
        }
      }

      .sign-out-button {
        text-transform: none;
      }
    }

    .header-left {
      justify-content: flex-start;
      
      @include media-breakpoint-up(md) {
        width: $sidenav-width;
      }

      .header-button {
        border-right: 1px solid $header-separator-color;
      }
    }

    .header-center {
      flex: 1;
      align-items: center;
      padding-left: 32px;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        padding-left: 16px;
      }

      .header-title {
        margin-bottom: 0;
        text-transform: uppercase;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        @include media-breakpoint-down(lg) {
          font-size: 2.5rem;
        }

        @include media-breakpoint-down(md) {
          font-size: 2rem;
        }

        @include media-breakpoint-down(sm) {
          font-size: 1.5rem;
        }
      }
    }

    .header-right {
      justify-content: flex-end;

      .header-button {
        border-left: 1px solid $header-separator-color;
      }
    }
  }
}

#nav {
  
}

#content {
  z-index: 1;
  height: 100%;
  display: flex;

  #header ~ & {
    padding-top: $header-height;

    @include media-breakpoint-down(md) {
      padding-top: $header-height-sm;
    }
  }

  #nav ~ & {
    padding-left: $sidenav-width;

    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
  }
}

.drawer-paper {
  background-color: $sidenav-bg !important;
  width: $sidenav-width;

  .logo {
    width: 100px;
    height: 100px;
    align-self: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .drawer-item {
    flex-direction: column;
    padding-top: 16px;
    padding-bottom: 16px;

    &.active-item {
      background-color: $sidenav-active-bg;
    }

    .drawer-item-icon {
      font-size: 50px;
      color: $lightCyan;
    }

    .drawer-item-label {
      font-size: 1.25rem;
      color: $white;
      font-weight: 600;
      text-align: center;
      line-height: 1.1;
    }
  }
}