@import "src/styles/variables";

.counter {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 15px !important;
  width: 450px;
  max-width: calc(100vw - 32px);
  padding-top: 16px;
  padding-bottom: 16px;

  &.has-progress-bar {
    padding-bottom: 0;
  }

  > .name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 34px;
    color: $gray-600;

    > .counter-name {
      font-family: 'regular';
      font-weight: 600;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100vw - 80px);
    }
  }

  > .hamburger-container {
    position: absolute;
    top: 0;
    right: 0;

    @include media-breakpoint-up(md) {
      padding: 5px;
    }
  }

  > .inner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .icon-container {
      overflow: hidden;
      border-radius: 30px;
      height: 56px;
      width: 56px;
      border-width: 2px;
      justify-content: center;
      align-items: center;

      .icon-button {
        font-size: 32px;
        color: $white;
      }

      &.decrement {
        background-color: $bittersweet;
      }

      &.increment {
        background-color: $limeGreen;
      }

      &.disabled {
        background-color: $disabled;
      }
    }

    .spinner-wrapper {
      padding-left: 15px;
      padding-right: 15px;
      color: $downy;
    }

    .counter-container {
      padding-left: 10px;
      padding-right: 10px;
      width: auto;

      .counter-input-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom-width: 0;

        .counter-text {
          align-self: center;
          min-height: 40;
          text-align: center;
          font-family: 'regular';
          opacity: 1;
          flex: 0;
          color: $black;
          font-size: 120px;
          line-height: 1;
          max-height: 120px;

          
          &.five-digits {
            font-size: 60px;
          }

          &.four-digits {
            font-size: 80px;
          }

          &.three-digits {
            font-size: 100px;
          }
        }
      }
    }
  }

  .progress {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-top: 16px;

    .progress-bar {
      background-color: $downy;

      &.progress-1 {
        background-color: $wildRice;
        color: $gray-600;
      }

      &.progress-2 {
        background-color: $porsche;
      }

      &.progress-3 {
        background-color: $sunset;
      }
    }
  }
}
