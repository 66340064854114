@import "variables";

$offset: 187;
$duration: 1.4s;

#loader-container {
  background: #f5f5f5;
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;

  > .spinner {
    -webkit-animation: rotator $duration linear infinite;
    animation: rotator $duration linear infinite;
    // position: fixed;
    // top: 50%;
    // left: 50%;
    // margin-left: -33px;
    // margin-top: -33px;
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@-webkit-keyframes rotator {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(270deg); }
}

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

.path {
  stroke-dasharray: $offset;
  stroke-dashoffset: 0;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: dash $duration ease-in-out infinite, colors ($duration*4) ease-in-out infinite;
  animation: dash $duration ease-in-out infinite, colors ($duration*4) ease-in-out infinite;
}

@-webkit-keyframes colors {
  0% { stroke: $primary; }
  25% { stroke: $danger; }
  50% { stroke: $warning; }
  75% { stroke: $success; }
  100% { stroke: $primary; }
}

@keyframes colors {
  0% { stroke: $primary; }
  25% { stroke: $danger; }
  50% { stroke: $warning; }
  75% { stroke: $success; }
  100% { stroke: $primary; }
}

@-webkit-keyframes dash {
  0% { stroke-dashoffset: $offset; }
  50% {
    stroke-dashoffset: $offset/4;
    -webkit-transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    -webkit-transform:rotate(450deg);
  }
}

@keyframes dash {
  0% { stroke-dashoffset: $offset; }
  50% {
    stroke-dashoffset: $offset/4;
    transform:rotate(135deg);
  }
  100% {
    stroke-dashoffset: $offset;
    transform:rotate(450deg);
  }
}
