@import "src/styles/variables";

.home-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .titles-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .logo {
      width: 12.5vw;
      height: 12.5vw;
    }

    .title {
      font-family: 'regular';
      font-size: 28px;
      color: $neonBlue;
      margin-top: 5px;
    }

    .secondary-title {
      font-family: 'regular';
      font-size: 15px;
      color: $wistful;
    }
  }

  .buttons-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 7.5vw;

    .sign-in-button {
      background-color: $puertoRico;
      padding: 17px;
      width: 65vw;
      margin-bottom: 10px;
      color: #fff;
      font-family: 'medium';
      font-size: 15px;
    }

    .sign-up-button {
      color: $neonBlue;
      font-family: 'medium';
      font-size: 15px;
    }
  }
}