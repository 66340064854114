@import "src/styles/variables";

.dashboard-page {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .paper-container {
    padding: 16px;
    margin: 16px;
    border-radius: 10px;
  }

  .stats-list {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;

    @include media-breakpoint-down(lg) {
      flex-direction: column;
      align-items: flex-start;
    }

    .stat-list-item {
      width: auto;

      .stat-icon {
        background-color: #98ACD1;
        width: 64px;
        height: 64px;
        border-radius: 10px;
        margin-right: 12px;
        font-size: 2.5rem;
      }

      .stat-primary {
        font-size: 1.25rem;
        color: #A6A6A6;

        @include media-breakpoint-down(lg) {
          font-size: 1rem;
        }
      }

      .stat-secondary {
        font-size: 1.75rem;
        font-weight: 600;
        color: $black;
      }
    }

    .stat-list-item + .stat-list-item {
      margin-left: 32px;

      @include media-breakpoint-down(lg) {
        margin-left: 0;
      }
    }
  }
}