@import "src/styles/variables";

@font-face {
  font-family: 'regular';
  src: local('DIN-Medium'), url(./assets/DIN-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'light';
  src: local('Rubik-Light'), url(./assets/Rubik-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'medium';
  src: local('Rubik-Medium'), url(./assets/Rubik-Medium.ttf) format('truetype');
}

.light-gradient {
  background: linear-gradient(135deg, $ghostWhite 0%, $lavender 100%);
}

.dark-gradient {
  background: linear-gradient(135deg, $neonBlue2 0%, $neonBlue 100%);
}
