@import "src/styles/variables";

.chart {
  .recharts-legend-wrapper {
    @include media-breakpoint-down(sm) {
      bottom: 0px !important;
    }

    .recharts-legend-item-text {
      font-weight: 500;

      @include media-breakpoint-down(sm) {
        font-size: 0.875rem;
      }
    }
  }
}
