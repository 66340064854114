@import "src/styles/variables";

.counters-page {
  flex: 1;
  background-color: $ghostWhite;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow-y: auto;

  > .hamburger-container {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 5px;
  }

  > .counters-container {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin-top: auto;
    margin-bottom: auto;

    @include media-breakpoint-down(sm) {
      justify-content: flex-start;
      padding-top: 64px;
    }
    
    > .counter + .counter {
      margin-top: 16px;
    }

    > .counter:last-child {
      margin-bottom: 0;
    }
  }

  > .button-wrapper {
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-button {
      border-radius: 25px;
      background-color: $prussianBlue;
      padding: 10px 25px;
      color: $white;
      font-family: 'regular';
      font-size: 22px;
    }
  }
}
