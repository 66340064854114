@import "src/styles/variables";

.base-input-field {
  .base-input-field-label {
    font-family: 'light';
    color: $nobel;
    font-weight: bold;
  }

  .base-input-field-input-wrapper {
    border: 1px solid $prussianBlue;
    background-color: $white !important;
    // width: 100%;
    padding-left: 10px;

    &:hover {
      background-color: $white !important;
    }

    .base-input-field-input {
      font-size: 18px;
      color: #333;

      @include media-breakpoint-up(sm) {
        min-height: 32px;
      }
    }

    &.MuiFilledInput-underline {
      &::before,
      &::after {
        border-bottom: none;
      }
    }
  }

  .base-input-field-error-text {
    color: $wildWatermelon !important;
  }
}
