@import "src/styles/variables";

$logo-row-height: unquote("max(7.5vw, 7.5vh)") !default;
$logo-outer-padding: 10px !default;

.onboarding-page {
  overflow-y: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .logo-title-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: $logo-outer-padding;
    padding-left: 7.5vw;
    height: $logo-row-height;

    .logo {
      width: calc(#{$logo-row-height} - #{2 * $logo-outer-padding});
      height: calc(#{$logo-row-height} - #{2 * $logo-outer-padding});
      margin-right: $logo-outer-padding;
    }

    .title {
      font-family: 'regular';
      font-size: 16px;
      color: $neonBlue;
    }
  }

  .inner-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-left: 12.5vw;
    padding-right: 12.5vw;
    padding-top: $header-height-sm / 2;
    padding-bottom: $header-height-sm / 2;

    @include media-breakpoint-up(sm) {
      padding-top: $header-height / 2;
      padding-bottom: $header-height / 2;
    }

    @include media-breakpoint-up(md) {
      padding-top: $header-height-sm;
      padding-bottom: $header-height-sm;
    }

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .inner-title {
        font-family: 'regular';
        font-size: 22px;
        color: scale-color($prussianBlue, $lightness: 25%);
      }

      .inner-secondary-title {
        margin-top: 5px;
        margin-bottom: 10px;
        font-family: 'regular';
        font-size: 13px;
        color: $perano;
      }

      .view-only-container {
        width: 100%;
        margin-top: 20px;
        border: 1px solid $prussianBlue;
        border-radius: 4px;
        background-color: $white;
        padding: 16px;

        > .view-field + .view-field {
          margin-top: 16px;
        }
      }
    }

    .button-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      .main-button {
        border-radius: 4px;
        background-color: $sidenav-active-bg;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 8px 0px;
        padding: 17px;
        width: 13rem;
        margin-bottom: 10px;
        margin-top: 30px;
        font-family: 'medium';
        font-size: 15px;
        color: $white;
      }

      .secondary-button {
        color: $white;
      }

      .cancel-button {
        color: $gray-600;
        font-family: 'medium';
        font-size: 15px;
      }
    }

    &.dark-gradient {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;

      .form {
        .inner-title {
          color: $white;
        }
      }
    }
  }
}
