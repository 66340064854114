.toast {
  width: $toast-max-width;
  max-width: 100%;
  @include font-size($toast-font-size);
  color: $toast-color;
  pointer-events: auto;
  background-color: $toast-background-color;
  background-clip: padding-box;
  border: $toast-border-width solid $toast-border-color;
  box-shadow: $toast-box-shadow;
  @include border-radius($toast-border-radius);

  &:not(.showing):not(.show) {
    opacity: 0;
  }

  &.hide {
    display: none;
  }
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;

  > :not(:last-child) {
    margin-bottom: $toast-spacing;
  }
}

.toast-header {
  display: flex;
  align-items: center;
  padding: $toast-padding-y $toast-padding-x;
  color: $toast-header-color;
  background-color: $toast-header-background-color;
  background-clip: padding-box;
  border-bottom: $toast-border-width solid $toast-header-border-color;
  @include border-top-radius(subtract($toast-border-radius, $toast-border-width));

  .btn-close {
    margin-right: $toast-padding-x / -2;
    margin-left: $toast-padding-x;
  }
}

.toast-body {
  padding: $toast-padding-x; // apply to both vertical and horizontal
  word-wrap: break-word;
}

.react-toast-container {
  bottom: 0 !important;
  padding-bottom: 0 !important;

  .react-toast {
    background-color: $gray-900;
    color: $gray-200;
    margin-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    min-height: 48px;

    &.Toastify__toast--error {
      background-color: $danger;
    }

    &.Toastify__toast--success {
      background-color: $success;
    }
    
    .react-toast-body {
      font-size: 0.875rem;
    }

    .Toastify__close-button {
      color: #fff !important;
      opacity: 0.7 !important;
    }

    .react-toast-progress {
      background: rgba(255, 255, 255, 0.7) !important;
    }
  }
}
